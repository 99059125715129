import './distributiva_atracciones_pa.scss';

import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';
import { Asgard } from '../src/components/Asgard/Asgard';
import { Freya } from '../src/components/Freya/Freya';
import H4 from '../src/components/Titles/TitleH4single/TitleH4single';
import Heimdall from '../src/components/Heimdall/Heimdall';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import Layout from '../src/components/Layout/Layout';
import SEO from '../src/helpers/seo';
import Buttons from '../src/components/Buttons/Buttons';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import AnounceCovid from '../src/components/AnounceCovid/AnounceCovid';
import Hod from '../src/components/Hod/Hod';
import tt from '../src/helpers/translation';
import InfoCtaPanel from '../src/components/InfoCtaPanel/InfoCtaPanel';

export const GatsbyQuery = ({ data, pathContext: { locale } }) => graphql`
  query ridesPa($locale: String!) {
    allRidesTitleBlock(filter: { park: { eq: "PortAventura" }, lang: { eq: $locale } }) {
      edges {
        node {
          alt
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allRidesTitle(filter: { park: { eq: "PortAventura" }, lang: { eq: $locale } }) {
      edges {
        node {
          name
          description
          text
        }
      }
    }
    allRidesSeoData(filter: { tag: { eq: "atracciones_pa" }, lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allRidesSubBlock(
      filter: { park: { eq: "PortAventura" }, lang: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
          imgsize
          title
          description
          seeMore
          disabled
          located
          features
          possibleFilters1
          possibleFilters2
          buttons {
            cta
            size
            color
            ctaText
          }
        }
      }
    }
    allRidesPromotedSubBlock(filter: { park: { eq: "PortAventura" }, lang: { eq: $locale } }) {
      edges {
        node {
          ctaText
          cta
          description
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
    allDistAtraccionesBreadCrumbBlock(
      filter: { park: { eq: "PortAventura" }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          tag
          destination_url
          name
        }
      }
    }
    allDistAtraccionesRichData(filter: { extra: { eq: "PortAventura" }, lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
          }
        }
      }
    }
  }
`;

class Rides extends React.Component {
  state = {
    once: false,
    throttleInterval: 150,
    offset: 0,
    partialVisibility: false,
    world: '',
    tipoServicio: '',
    tipoMenu: '',
    initialFilterStateMundo: true,
    initialFilterStateMenu: true,
    initialFilterStateServicios: true,
  };

  handleFilter = (name, tag) => {
    if (tag === tt('MUNDO', this.props.pageContext.locale)) {
      const newFilterData = { ...this.state.filterData, world: name };
      this.setState({ world: name, initialFilterStateMundo: false, filterData: newFilterData });
    } else if (tag === tt('TIPO DE ATRACCIÓN', this.props.pageContext.locale)) {
      const newFilterData = { ...this.state.filterData, tipoServicio: name };
      this.setState({
        tipoServicio: name,
        initialFilterStateMenu: false,
        filterData: newFilterData,
      });
    } else if (tag === tt('TIPO DE SERVICIO', this.props.pageContext.locale)) {
      const newFilterData = { ...this.state.filterData, tipoMenu: name };
      this.setState({
        tipoMenu: name,
        initialFilterStateServicios: false,
        filterData: newFilterData,
      });
    }
  };

  resetFilter = () => {
    const newFilterData = new Object();
    this.setState({
      initialFilterStateMundo: true,
      initialFilterStateMenu: true,
      initialFilterStateServicios: true,
      filterData: newFilterData,
    });
  };

  render() {
    const heimdallData = {
      name: this.props.data.allRidesTitle.edges[0].node.name,
      subtitle: this.props.data.allRidesTitle.edges[0].node.description.replace(
        /<\/?[^>]+(>|$)/g,
        ''
      ),
      image: {
        alt: this.props.data.allRidesTitleBlock.edges[0].node.alt,
        url: this.props.data.allRidesTitleBlock.edges[0].node.localImage.childImageSharp.fluid,
      },
    };

    const buttons = {
      cta: tt('/entradas', this.props.pageContext.locale),
      size: 'alone',
      color: 'orange',
      ctaText: tt('COMPRAR ENTRADAS', this.props.pageContext.locale),
    };

    const AsgardData = {
      image:
        'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/images/images/000/009/030/original/pap.png',
      text: `${this.props.data.allRidesSubBlock.edges.length} ${tt(
        'ATRACCIONES',
        this.props.pageContext.locale
      )}`,
      filters: [
        {
          tag: tt('MUNDO', this.props.pageContext.locale),
          options: [
            { value: tt('Mediterrània', this.props.pageContext.locale), label: 'Mediterrània' },
            { value: tt('China', this.props.pageContext.locale), label: 'China' },
            { value: tt('Polynesia', this.props.pageContext.locale), label: 'Polynesia' },
            { value: tt('México', this.props.pageContext.locale), label: 'México' },
            {
              value: tt('SésamoAventura', this.props.pageContext.locale),
              label: 'Sesamo Aventura',
            },
            { value: tt('Far West', this.props.pageContext.locale), label: 'Far West' },
          ],
        },
        {
          tag: tt('TIPO DE ATRACCIÓN', this.props.pageContext.locale),
          options: [
            { value: tt('Fuerte', this.props.pageContext.locale), label: 'hard' },
            { value: tt('Moderada', this.props.pageContext.locale), label: 'moderate' },
            { value: tt('Infantil', this.props.pageContext.locale), label: 'children' },
          ],
        },
        {
          tag: tt('TIPO DE SERVICIO', this.props.pageContext.locale),
          options: [
            { value: tt('Express Pass', this.props.pageContext.locale), label: 'express_pass' },
            {
              value: tt('Acceso discapacitados', this.props.pageContext.locale),
              label: 'disabled',
            },
            {
              value: tt('Fotografía - atracciones', this.props.pageContext.locale),
              label: 'photo_ride',
            },
            { value: tt('Video Ride', this.props.pageContext.locale), label: 'video_ride' },
          ],
        },
      ],
    };
    const freyaDataRides = {
      cards: this.props.data.allRidesSubBlock.edges
        .map((ride) => ({
          image: ride.node.localImage.childImageSharp.fluid,
          altImage: ride.node.image_alt,
          imgsize: ride.node.imgsize,
          title: ride.node.title,
          description: ride.node.description,
          disabled: ride.node.disabled,
          seeMore: ride.node.seeMore,
          located:
            ride.node.located === 'Sesamo Aventura'
              ? 'SésamoAventura'
              : ride.node.located.charAt(0).toUpperCase() + ride.node.located.slice(1),
          features: ride.node.features,
          possibleFilters: ride.node.possibleFilters1 + ride.node.possibleFilters2,
          buttons: {
            cta: ride.node.buttons.cta,
            size: ride.node.buttons.size,
            color: ride.node.buttons.color,
            ctaText: ride.node.buttons.ctaText,
          },
        }))
        .filter((item) => {
          const setFilters = [];
          for (const prop in this.state.filterData) setFilters.push(this.state.filterData[prop]);
          if (!setFilters.length) return item;
          if (setFilters.every((elem) => item.possibleFilters.indexOf(elem) > -1)) return item;
        }),
    };

    const freyaData = {
      title: <FormattedMessage id="rides-promotions-opcion1" />,
      cards: this.props.data.allRidesPromotedSubBlock.edges.map((shop) => ({
        image: shop.node.localImage.childImageSharp.fluid,
        altImage: shop.node.image_alt,
        title: shop.node.description,
        imgsize: 'promoted',
        buttons: {
          cta: shop.node.cta,
          size: 'alone',
          color: 'white',
          ctaText: shop.node.ctaText,
        },
      })),
    };
    const infoButtonsData = [
      {
        cta: tt('/atracciones/mejores-atracciones', this.props.pageContext.locale),
        size: 'small',
        color: 'white',
        ctaText: tt('Leer más', this.props.pageContext.locale),
        description: tt('<p><b>MEJORES</b> ATRACCIONES</p>', this.props.pageContext.locale),
        border: false,
        blank: false,
      },
      {
        cta: tt(
          'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/documents/documents/000/015/868/original/horarios_atracciones_diciembre.pdf',
          this.props.pageContext.locale
        ),
        size: 'small',
        color: 'white',
        // ctaText: tt('Leer más', this.props.pageContext.locale),
        ctaText: 'proximamente',
        locale: this.props.pageContext.locale,
        description: tt('<p><b>HORARIOS</b> ATRACCIONES</p>', this.props.pageContext.locale),
        border: true,
        blank: true,
      },
      {
        cta: tt('/atracciones/requisitos', this.props.pageContext.locale),
        size: 'small',
        color: 'white',
        ctaText: tt('Leer más', this.props.pageContext.locale),
        description: tt('<p><b>REQUISITOS</b> DE ALTURA</p>', this.props.pageContext.locale),
        border: false,
        blank: false,
      },
    ];

    return (
      <Layout layoutFromOld  locale={this.props.pageContext.locale} pageName={this.props.pageContext.pageName}>
        <SEO
          title={this.props.data.allRidesSeoData.edges[0].node._0.title}
          description={this.props.data.allRidesSeoData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <Hod
          data={this.props.data.allDistAtraccionesRichData.edges[0].node.rich_markup}
          url={this.props.pageContext.url}
          img={this.props.data.allRidesTitleBlock.edges[0].node.image}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="Asgard-box">
            <Asgard
              locale={this.props.pageContext.locale}
              data={AsgardData}
              filterStatusMundo={this.state.initialFilterStateMundo}
              filterStatusTipoMenu={this.state.initialFilterStateMenu}
              filterStatusTipoServicios={this.state.initialFilterStateServicios}
              resetFilter={this.resetFilter}
              filterChange={this.handleFilter}
            />
          </div>
          <Buttons
            link={buttons.cta}
            size={buttons.size}
            color={buttons.color}
            text={buttons.ctaText}
          />
          <BlueArrow />
          <div className="general-index">
            <div className="rides-content">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allDistAtraccionesBreadCrumbBlock.edges[0].node.name}
              />
              <InfoCtaPanel buttons={infoButtonsData} />
              <AnounceCovid
                text={this.props.data.allRidesTitle.edges[0].node.description.replace(
                  /<\/?[^>]+(>|$)/g,
                  ''
                )}
              />
              <Freya data={freyaDataRides} />
              <div className="rides-content-promo">
                <H4 title={tt('PROMOCIONES RELACIONADAS', this.props.pageContext.locale)} />
                <Freya data={freyaData} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

/* eslint-disable react/require-default-props */
Rides.propTypes = {
  RidesData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
  }),
};
/* eslint-enable react/require-default-props */

export default Rides;
